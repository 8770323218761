// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	Checkbox, CheckboxGroup,
	Heading,
	Select,
	Box,
	Input,
	Center
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import React, { useEffect, useState } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { SiGooglesheets } from "react-icons/si";
import { MdFilterAlt, MdCalendarMonth } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import { IoHandLeftSharp } from 'react-icons/io5';
import { useListContext } from '../../contexts/listContext';
import { sheetDownload } from 'components/sheetDownload';
import { BsFileEarmarkBarGraphFill } from "react-icons/bs";
import Report from "views/admin/default/Report";



export default function HeaderLinks(props) {
	const { secondary } = props;
	const history = useHistory(); // Get the history object
	const location = useLocation();
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	const [checkedItems, setCheckedItems] = React.useState([]);
	const [checkedKeywords, setCheckedKeywords] = React.useState([]);
	const [checkedVerificationStatus, setCheckedVerificationStatus] = React.useState([]);
	const [checkedAbuseTypes, setCheckedAbuseTypes] = React.useState([]);

	const [platforms, setPlatforms] = React.useState([]);
	const [keywords, setKeywords] = React.useState([]);
	const [insertKeyword, setInsertKeyword] = React.useState([]);
	const { list, setList } = useListContext();
	const { checkedPlatforms, setCheckedPlatforms } = useListContext();
	const { verificationStatus, setVerificationStatus } = useListContext();
	const { dateFilter, setDateFilter } = useListContext();
	const [abuseTypes, setAbuseTypes] = React.useState([]);
	const { abuse_type, setAbuse_type } = useListContext();
	let userName = JSON.parse(localStorage.getItem('PAuser'));



	// let calendarOptions = [{ id: 1, name: "Today" }, { id: 2, name: "This week" }, { id: 3, name: "This month" }, { id: 4, name: "This Year" }, { id: 5, name: "Past" }, { id: 0, name: "All" }];
	// 	0: 'today'   1: 'this week'   2: 'last week'   3: 'this month'  4: 'last month'
	// 	5: 'this year'   6: 'last year'   7: 'past'    8: 'All'




	const fetchPlatform = async () => {

		// Get the location object
		try {
			const userInfo = JSON.parse(localStorage.getItem('PAuser'));
			//console.log("param pf", userInfo.message.result.id);
			const response = await fetch('https://anticounterfeit.co.in/api/platforms/Filter', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					uid: userInfo.message.result.id,
				})
			});

			const json = await response.json();

			if (response.ok) {
				setPlatforms(json);
				//console.log('platforms', json);
				const allPlatformIds = json.map((pf) => pf.id);
				setCheckedItems(allPlatformIds);
			} else if (response.status === 400) {
				//console.log(json);
				alert(json.message);
			}
		} catch (e) {
			//console.log("error in fetchProducts");
		}
	};
	const getAbuseTypes = async () => {
		try {
			const response = await fetch('https://anticounterfeit.co.in/api/abuses/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},

			});

			const json = await response.json();

			if (response.ok) {
				//console.log("abuses fetched", json);
				setAbuseTypes(json);
				const allAbuseTypeIds = json.map((abuse) => abuse.id);
				setCheckedAbuseTypes(allAbuseTypeIds);
			} else if (response.status === 400) {
				//console.log(json);
				alert(json.message);
			}
			// setStatusUpdate(true)
		} catch (e) {
			//console.log("error in getAbuseTypes", e);
		}
	}
	const fetchKeywords = async () => {
		try {
			const userInfo = JSON.parse(localStorage.getItem('PAuser'));
			//console.log("param", userInfo.message.result.id);
			const response = await fetch('https://anticounterfeit.co.in/api/keyword/getKeywordsForFilter/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
				})
			});

			const json = await response.json();

			if (response.ok) {
				setKeywords(json);
				setInsertKeyword([]);
				//console.log('keywords added', json)
				const allKeywordIds = json.map((kw) => kw.id);
				setCheckedKeywords(allKeywordIds);
			} else if (response.status === 400) {
				//console.log(json);
				alert(json.message);
			}
		} catch (e) {
			//console.log("error in getkeywords");
		}
	};



	useEffect(() => {
		fetchPlatform();
		fetchKeywords();
		getAbuseTypes();
	}, [])

	useEffect(() => {
		const allVerificationIds = verifcationOptions.map((opt) => opt.is_text);
		setCheckedVerificationStatus(allVerificationIds);
	}, []);

	const handlePlatformCheck = async (pf) => {
		const isChecked = checkedItems.includes(pf.id);
		let newCheckedItems;

		if (isChecked) {
			newCheckedItems = checkedItems.filter((id) => id !== pf.id);
		} else {
			newCheckedItems = [...checkedItems, pf.id];
		}

		setCheckedItems(newCheckedItems);


		// Log checked platform IDs and names
		const checkedPF = platforms.filter((platform) => newCheckedItems.includes(platform.id));
		//console.log('Checked Platforms:', checkedPF.map((platform) => ({ id: platform.id, name: platform.platform_name })));
		localStorage.removeItem('checkedPF');
		localStorage.setItem('checkedPF', JSON.stringify(checkedPF.map((platform) => ({ id: platform.id, name: platform.platform_name }))));
		setCheckedPlatforms(checkedPF);
	}
	const handleKeywordCheck = async (pf) => {
		const isChecked = checkedKeywords.includes(pf.id);
		let newCheckedItems;

		if (isChecked) {
			newCheckedItems = checkedKeywords.filter((id) => id !== pf.id);
		} else {
			newCheckedItems = [...checkedKeywords, pf.id];
		}

		setCheckedKeywords(newCheckedItems);
		localStorage.removeItem('checkedKeywords');
		localStorage.setItem('checkedKeywords', JSON.stringify(newCheckedItems));


		// Log checked platform IDs and names
		const lists = keywords.filter((platform) => newCheckedItems.includes(platform.id));
		//console.log('Checked keywords:', lists.map((ele) => ({ id: ele.id, name: ele.name })));
		localStorage.removeItem('checkedKeywords');
		localStorage.setItem('checkedKeywords', JSON.stringify(lists.map((ele) => ({ id: ele.id, name: ele.name }))));
		setList(lists);

	}
	const handleVerificationCheck = async (ele) => {
		const isChecked = checkedVerificationStatus.includes(ele.is_text);
		let newCheckedItems;

		if (isChecked) {
			newCheckedItems = checkedVerificationStatus.filter((id) => id !== ele.is_text);
		} else {
			newCheckedItems = [...checkedVerificationStatus, ele.is_text];
		}

		setCheckedVerificationStatus(newCheckedItems);
		localStorage.removeItem('checked_Verification_Status');
		localStorage.setItem('checked_Verification_Status', JSON.stringify(newCheckedItems));


		// Log checked platform IDs and names
		const lists = verifcationOptions.filter((platform) => newCheckedItems.includes(platform.is_text));
		//console.log('Checked verificationStatus:', lists);
		localStorage.removeItem('checked_Verification_Status');
		localStorage.setItem('checked_Verification_Status', JSON.stringify(lists.map((ele) => ({ is_text: ele.is_text, name: ele.name }))));
		setVerificationStatus(lists);
		// //console.log("current Location",location);

	}
	const handleAbuseCheck = async (ele) => {
		const isChecked = checkedAbuseTypes.includes(ele.id);
		let newCheckedItems;

		if (isChecked) {
			newCheckedItems = checkedAbuseTypes.filter((id) => id !== ele.id);
		} else {
			newCheckedItems = [...checkedAbuseTypes, ele.id];
		}

		setCheckedAbuseTypes(newCheckedItems);
		localStorage.removeItem('checked_Abuse_type');
		localStorage.setItem('checked_Abuse_type', JSON.stringify(newCheckedItems));


		// Log checked platform IDs and names
		const lists = abuseTypes.filter((item) => newCheckedItems.includes(item.id));
		//console.log('Checked Abuses:', lists);
		localStorage.removeItem('checked_Abuse_type');
		localStorage.setItem('checked_Abuse_type', JSON.stringify(lists.map((ele) => ({ id: ele.is_text, abuse_type: ele.abuse_type }))));
		setAbuse_type(lists);
		// //console.log("current Location",location);

	}
	const verifcationOptions = [{
		is_text: -1, name: "Text & image NOT match"
	},
	{ is_text: 0, name: "Text NOT match & image match" },
	{ is_text: 1, name: "Text match & image NOT match" },
		,
	{ is_text: 2, name: "Text & Image both Match" }];
	const handleLogOut = async () => {
		await localStorage.removeItem('PAuser');
		history.push('/');
	}

	const pathsForDates = ["/admin/dashboard", "/admin/uncrawled", "/admin/products", "/admin/suspicious", "/admin/test"]


	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>



			<SidebarResponsive routes={routes} />

			<Menu>
				{(pathsForDates.includes(location.pathname)) && <>
					<MenuButton p='0px'>
						<Icon
							// mt='6px'
							as={MdCalendarMonth}
							color="gray.600"
							w='25px'
							h='25px'
							me='10px'
						/>
					</MenuButton>
					<MenuList
						boxShadow={shadow}
						p='10px'
						me={{ base: "10px", md: "unset" }}

						borderRadius='20px'
						bg={menuBg}
						border='none'
						mt='-50px'
						maxHeight="70vh"   // Adjust the maxHeight as needed
						overflowY="auto"
					>
						<Flex flexDirection='row'>
							<Flex flexDirection='row' w='fit-content'>
								<Center><Text me='10px'>From: </Text></Center>
								<Input placeholder='Select Date and Time' size='md' onChange={(e) => setDateFilter({ ...dateFilter, startDate: e.target.value })} type='date' />
							</Flex>
							<Flex flexDirection='row' ms='15' w='fit-content'>
								<Center><Text me='10px'>To: </Text></Center>
								<Input placeholder='Select Date and Time' size='md' onChange={(e) => setDateFilter({ ...dateFilter, endDate: e.target.value })} value={dateFilter.endDate} type='date' />
							</Flex>
						</Flex>

					</MenuList>
				</>}
			</Menu>
			<Menu>
				{(location.pathname === "/admin/dashboard") && <>
					<MenuButton p='0px' onClick={() => sheetDownload(dateFilter)}>
						<Icon
							as={SiGooglesheets}
							color="gray.600"
							w='22px'
							h='22px'
							me='10px'
						/>
					</MenuButton>
				</>}
			</Menu>
			<Menu>
				{(location.pathname === "/admin/dashboard") && (
					<Link href={`/report?dateFilter=${JSON.stringify(dateFilter)}&brand=${userName.message.result.name}`} target="_blank">
						<MenuButton p='0px'>
							<Icon
								as={BsFileEarmarkBarGraphFill}
								color="gray.600"
								w='22px'
								h='22px'
								me='10px'
							/>
						</MenuButton>
					</Link>
				)}

			</Menu>

			<Menu>
				{(location.pathname !== "/admin/dashboard" && location.pathname !== "/admin/lookalike") && <>
					<MenuButton p='0px'>
						<Icon
							mt='6px'
							as={MdFilterAlt}
							color="gray.600"
							w='25px'
							h='25px'
							me='10px'
						/>
					</MenuButton>
					<MenuList
						boxShadow={shadow}
						p='20px'
						me={{ base: "30px", md: "unset" }}
						borderRadius='20px'
						bg={menuBg}
						border='none'
						mt='22px'
						maxHeight="70vh"   // Adjust the maxHeight as needed
						overflowY="auto"
						sx={{
							'&::-webkit-scrollbar': {
								width: '8px',
							},
							'&::-webkit-scrollbar-thumb': {
								bg: 'gray.300',
								borderRadius: 'full',
							},
							'&::-webkit-scrollbar-track': {
								bg: 'gray.100',
							},
						}}
						w={{ base: "360px", md: "unset" }}>
						<Heading as='h4' size="sm" textAlign="left">Platforms</Heading>
						<Flex flexDirection='column'>
							{platforms.map((pf) =>
								<Checkbox
									key={pf.id}
									isChecked={checkedItems.includes(pf.id)}
									onChange={() => handlePlatformCheck(pf)}
								>
									{pf.platform_name}
								</Checkbox>
							)}
						</Flex>

						<Heading as='h4' size="sm" textAlign="left">Keywords</Heading>
						<Flex flexDirection='column'>
							{keywords.map((pf) =>
								<Checkbox
									key={pf.id}
									isChecked={checkedKeywords.includes(pf.id)}
									onChange={() => handleKeywordCheck(pf)}
								>
									{pf.name}
								</Checkbox>
							)}
						</Flex>
						{location.pathname === "/admin/uncrawled" && <>
							<Heading as='h4' size="sm" textAlign="left">Verification</Heading>
							<Flex flexDirection='column'>
								{verifcationOptions.map((ele, index) =>
									<Checkbox
										key={index}
										isChecked={checkedVerificationStatus.includes(ele.is_text)}
										onChange={() => handleVerificationCheck(ele)}
									>
										{ele.name}
									</Checkbox>
								)}
							</Flex>
						</>}
						{location.pathname === "/admin/suspicious" && <>
							<Heading as='h4' size="sm" textAlign="left">Abuse Types</Heading>
							<Flex flexDirection='column'>

								{abuseTypes.map((ele, index) =>
									<Checkbox
										key={index}
										isChecked={checkedAbuseTypes.includes(ele.id)}
										onChange={() => handleAbuseCheck(ele)}
									>
										{ele.abuse_type}
									</Checkbox>
								)}
							</Flex>
						</>}
					</MenuList>
				</>}
			</Menu>

			{/* <ThemeEditor navbarIcon={navbarIcon} /> */}

			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name="Adela Parkson"
						bg="#11047A"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, Hello
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						{/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem> */}
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Text fontSize="sm" onClick={handleLogOut}>Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
