
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Checkbox,
  Tooltip,
  Icon,
  Alert,
  AlertIcon,
  Stack
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/admin/Uncrawled/components/HistoryItem";
import WhitelistedProduct from 'views/admin/Detected Product/components/WhitelistedProduct';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';


import Card from "components/card/Card.js";
import { useListContext } from '../../../contexts/listContext';
import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdAlbum } from "react-icons/md";





export default function Products() {
  const [products, setProducts] = useState([]);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const [productsToShow, setProductsToShow] = useState(20); // Initial number of products to show
  const productsPerPage = 20; // Number of products to load per page


  const [productByStatus, setProductByStatus] = useState([]);
  const [insertSeller, setInsertSeller] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const { list, checkedPlatforms, verificationStatus, setVerificationStatus } = useListContext();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [first, setFirst] = useState(true);
  const [selectedProds, setSelectedProds] = useState([]);
  const [status, setStatus] = useState('');
  const [text, setText] = useState();
  const [toggleX, setToggleX] = useState(false);
  const [toggleOg, setToggleOg] = useState(false);
  const [sortedProducts, setSortedProducts] = useState([]);




  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }



  const fetchProducts = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/products/Xstatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();

      if (response.ok) {
        setFetchedProducts(json);

        setProducts(json);
        // handleSuccessAlert('Products fetched')
      } else if (response.status === 400) {
        //console.log(json);
        handleFailureAlert(json.message);

      }
    } catch (e) {
      //console.log("error in fetchProducts");
    }
  };




  const loadMore = () => {
    var newNo = productsToShow + productsPerPage
    setProductsToShow(newNo);
    if (isChecked) {
      const newIds = products.slice(0, newNo).map((item) => ({ pd_id: item.pd_id }));
      setInsertSeller((prevInsertSeller) => newIds);
      setSelectedAll(true);
    }
  };

  const loadLess = () => {
    var newNo = productsToShow - productsPerPage;
    setProductsToShow(newNo);
    if (isChecked) {
      const newIds = products.slice(0, newNo).map((item) => ({ pd_id: item.pd_id }));
      setInsertSeller((prevInsertSeller) => newIds);
      setSelectedAll(true);
    }
  };

  useEffect(() => {
    fetchProducts();
    handleFilter();
  }, [userInfo?.message?.result.id]);


  const handleInsertSeller = async (pd_id) => {
    try {

      const existingIndex = await insertSeller.findIndex(item => item.pd_id === pd_id);

      if (existingIndex !== -1) {
        // Product already exists, update its status
        await setInsertSeller(prev => {
          const updatedSeller = [...prev];
          return updatedSeller;
        });
      } else {
        // Product does not exist, add it to the list
        await setInsertSeller([...insertSeller, { pd_id }]);
      }
      //console.log('insertSeller', insertSeller);
    } catch (err) {
      console.error('err in handleInserSeller', err)
    }
  }

  const removeFromInsert = async (pd_id) => {
    await setInsertSeller(insertSeller.filter((item) => {
      return !(item.pd_id === pd_id);
    }));
    //console.log('removed from list new Insert Seller:', insertSeller);
  }

  const handleSaveChanges = async () => {
    try {
      //console.log("insert seller", insertSeller);
      if (status) {
        const uid = JSON.parse(localStorage.getItem('PAuser')).message.result.id
        //console.log('uid', uid);
        const response = await fetch('https://anticounterfeit.co.in/api/products/updateProducts/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            products: insertSeller,
            status: status,
            is_text: text,
            uid: uid
          })
        });

        const json = await response.json();

        if (response.ok) {
          handleSuccessAlert("Status Updated");
          fetchProducts();
          //console.log('Empty insert seller', insertSeller)
        } else if (response.status === 400) {
          //console.log(json);
          handleFailureAlert(json.message);

        }
      }
    } catch (e) {
      //console.log("error in handleSavechanges", e);
    }
  }

  const handleSearch = async (text) => {
    try {
      // setProducts(fetchedProducts);
      if (text) {
        setProducts(sortedProducts.filter((item) =>
          item.title?.toLowerCase().includes(text?.toLowerCase()) ||
          item.keyword?.toLowerCase().includes(text?.toLowerCase()) ||
          item.prod_code?.toLowerCase().includes(text?.toLowerCase())
        ));
      } else {
        setProducts(sortedProducts);
      }
    } catch (e) {
      console.error('error in search', e)
    }
  }

  const handleFilter = async () => {
    try {
      let keywordNames = list.map((item) => item.name?.toLowerCase());
      let platformIds = checkedPlatforms.map((item) => item.id);
      let verification = verificationStatus.map((item) => item.is_text);
      let allProducts = fetchedProducts;
      let kfilter;
      let pfilter;
      let vfilter;
      if (keywordNames.length !== 0) {
        kfilter = allProducts.filter((item) =>
          keywordNames?.includes(item.keyword)
        )
      } else {
        kfilter = allProducts;
      }
      if (platformIds.length !== 0) {
        pfilter = kfilter.filter((item) =>
          platformIds?.includes(item.platform_id)
        )
      } else {
        pfilter = kfilter;
      }
      if (verification.length !== 0) {
        vfilter = pfilter.filter((item) =>
          verification.includes(item.is_text) || (verification.includes(2) && item.is_text === 3)
        );
      } else {
        vfilter = pfilter;
      }
      setSortedProducts(vfilter);
      setProducts(vfilter);

    } catch (err) {
      //console.log('error in handleFilter', err)
    }
  }

  useEffect(() => {
    handleFilter();
  }, [list, checkedPlatforms, fetchedProducts, verificationStatus]);

  const handleSelectAll = async (items) => {
    setIsChecked(!isChecked);
    setFirst(false);
    if (!isChecked) {
      //console.log('Checkbox is checked!');
      const newIds = items.map((item) => ({ pd_id: item.pd_id }));
      setInsertSeller((prevInsertSeller) => newIds);
      setSelectedAll(true);

      //console.log(insertSeller);
    } else {
      setSelectedAll(false);
      setInsertSeller([])
      //console.log(insertSeller);
    }
  }




  const handleToggleX = async () => {
    setToggleX(!toggleX);
    setToggleOg(false);
    if (!toggleX) {
      setStatus('X');
      setText(-1)
    } else {
      setStatus('');
      setText()
    }
  }
  const handleToggleOg = async () => {
    setToggleOg(!toggleOg);
    setToggleX(false);
    if (!toggleOg) {
      setStatus('A');
      setText(3)
    } else {
      setStatus('');
      setText()
    }
  }



  return (
    <Box colorScheme='messenger' pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}

      <Card p='0px' w='100%'>

        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          py='18px'>
          <SearchBar m="10px" mt="0px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />
          <Flex alignSelf='right' >
            <Text color={textColor}
              fontSize={{
                base: "md",
              }}
              fontWeight='bold'
              m="10px"
              mr='5px'>Select all</Text>
            <Checkbox
              isChecked={isChecked}
              onChange={() => handleSelectAll(products.slice(0, productsToShow))}
              mr='2vw'>
            </Checkbox>
            <Flex mr='2vw'>
              <Tooltip label='Mark as Verified'>
                <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggleOg()}>
                  <Icon as={MdCheckCircle} color={toggleOg === true ? 'green' : 'gray'} />
                  {/* color={toggleOg === true ? 'green' : 'gray'} */}
                </Button>
              </Tooltip>
              <Tooltip label='Remove'>
                <Button colorScheme="gray" fontSize="20px" p='0px' onClick={() => handleToggleX()}>
                  <Icon as={MdCancel} color={toggleX === true ? 'red' : 'gray'} />
                  {/* color={toggleX === true ? 'red' : 'gray'} */}
                </Button>
              </Tooltip>
            </Flex>
            <Button variant='action' onClick={() => { handleSaveChanges() }} >Save Changes</Button>
          </Flex>
        </Flex>
        {products.slice(0, productsToShow).map((prod, index) => {
          return (
            <HistoryItem
              key={index}
              index={index}
              product={prod}
              handleInsertSeller={handleInsertSeller}
              removeFromInsert={removeFromInsert}
              selectAll={selectedAll}
              insertSeller={insertSeller}
              first={first}
            />
          )
        })}

      </Card>

      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'  >
            <AlertIcon />
            {successAlert}

          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>

      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
        Show less...
      </Text></Link>
      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
        Show more...
      </Text></Link>

      {/* Delete Product */}
    </Box>

  );
}
