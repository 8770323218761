
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Alert,
  AlertIcon
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/admin/Detected Product/components/HistoryItem";
import WhitelistedProduct from 'views/admin/Detected Product/components/WhitelistedProduct';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';


import Card from "components/card/Card.js";
import { useListContext } from '../../../contexts/listContext';



export default function Products() {
  const [products, setProducts] = useState([]);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const [productsToShow, setProductsToShow] = useState(20); // Initial number of products to show
  const productsPerPage = 20; // Number of products to load per page


  const [productByStatus, setProductByStatus] = useState([]);
  const [insertSeller, setInsertSeller] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [reportedSeller, setReportedSeller] = useState([]);
  const [blackListSeller, setBlackListSeller] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const { list, checkedPlatforms, dateFilter } = useListContext();
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);


  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }

  const fetchProducts = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/products/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();

      if (response.ok) {
        setFetchedProducts(json);
        // setProducts(json);
        //console.log('Products fetched')
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);

        handleFailureAlert("An error occured");
      }
    } catch (e) {
      //console.log("error in fetchProducts");
      handleFailureAlert("An error occured");
    }
  };

  const fetchProductsByStatus = async (status) => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/products/byStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          status: status
        })
      });

      const json = await response.json();

      if (response.ok) {
        setProductByStatus(json);
        //console.log('status Products fetched')
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert('Error in fetching products');
      }
    } catch (e) {
      //console.log("error in fetchProducts");
      handleFailureAlert('Error in fetching products');

    }
  };


  const loadMore = () => {
    setProductsToShow(productsToShow + productsPerPage);
  };
  const loadLess = () => {
    setProductsToShow(productsToShow - productsPerPage);
  };

  useEffect(() => {
    fetchProducts();
    handleFilter();
  }, [userInfo?.message?.result.id]);


  const handleInsertSeller = async (product, seller_status) => {
    try {
      if (seller_status === 'X') {
        const existingIndex = deleteList.findIndex(item => item.product.psm_id === product.psm_id);
        if (existingIndex !== -1) {
          // Product already exists, update its status
          setDeleteList(prev => {
            const updatedSeller = [...prev];
            updatedSeller[existingIndex].seller_status = seller_status;
            return updatedSeller;
          });
        } else {
          // Product does not exist, add it to the list
          setDeleteList([...deleteList, { product, seller_status }]);
        }
        //console.log('deleted List', deleteList);
      } else {
        const existingIndex = insertSeller.findIndex(item => item.product.psm_id === product.psm_id);
        const existingIndexDeleteList = deleteList.findIndex(item => item.product.psm_id === product.psm_id);

        if (existingIndex !== -1) {
          // Product already exists, update its status
          setInsertSeller(prev => {
            const updatedSeller = [...prev];
            updatedSeller[existingIndex].seller_status = seller_status;
            return updatedSeller;
          });
        } else if (existingIndexDeleteList !== 1) {
          setDeleteList(deleteList.filter((item) => {
            return !(item.product.psm_id === product.psm_id);
          }));
          setInsertSeller([...insertSeller, { product, seller_status }]);
        } else {
          // Product does not exist, add it to the list
          setInsertSeller([...insertSeller, { product, seller_status }]);
        }
        //console.log('insertSeller', insertSeller);
      }
    } catch (err) {
      console.error('err in handleInserSeller', err)
    }
  }

  const removeFromInsert = async (product, seller_status) => {
    if (seller_status === 'X') {
      setDeleteList(deleteList.filter((item) => {
        return !(item.product.psm_id === product.psm_id);
      }));
      //console.log('removed from list new delete products:', deleteList);
    } else {
      setInsertSeller(insertSeller.filter((item) => {
        return !(item.product.psm_id === product.psm_id);
      }));
      //console.log('removed from list new Insert Seller:', insertSeller);
    }
  }

  const handleSaveChanges = async () => {
    try {
      const uid = await JSON.parse(localStorage.getItem('PAuser')).message.result.id
      //console.log('uid', uid);
      const response = await fetch('https://anticounterfeit.co.in/api/sellers/insertSeller/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          products: insertSeller,
          uid: uid
        })
      });

      const json = await response.json();

      if (response.ok) {
        fetchProducts();
        //console.log('Empty insert seller', insertSeller)
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Error saving Change. Please try again");
        return;
      }


      //console.log(deleteList);
      const response1 = await fetch('https://anticounterfeit.co.in/api/sellers/updateSellerStatus/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sellers: deleteList,
          uid: uid
        })
      });

      const json1 = await response1.json();

      if (response1.ok) {
        // alert("Deleted Products");
        await fetchProducts();
        setDeleteList([]);
        //console.log('Empty deleteList', deleteList)
      } else if (response.status === 400) {
        //console.log(json1);
        // alert(json1.message);
        handleFailureAlert("Error saving changes. Please try again");
        return;
      }
      await setStatusUpdate(true);
      // alert("Changes Saved");
      handleSuccessAlert("Changes Saved");
    } catch (e) {
      //console.log("error in handleSavechanges", e);
      handleFailureAlert("Error saving changes. Please try again");
    }
  }

  const fetchKeywordOptions = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('PAuser'));
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/keyword/getKeywordsForFilter/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();

      if (response.ok) {
        setKeywordOptions(json);
        // setInsertKeyword([]);
        //console.log('keywords edit', json)
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Failed to fetch Keywords");
      }
    } catch (e) {
      //console.log("error in getkeywords");
    }
  };

  useEffect(() => {
    fetchKeywordOptions();
  }, []);

  const handleSearch = async (text) => {
    try {
      // setProducts(fetchedProducts);
      if (text) {
        setProducts(sortedProducts.filter((item) =>
          item.title?.toLowerCase().includes(text?.toLowerCase()) ||
          item.keyword?.toLowerCase().includes(text?.toLowerCase()) ||
          item.seller_name?.toLowerCase().includes(text?.toLowerCase()) ||
          item.prod_code?.toLowerCase().includes(text?.toLowerCase())
        ));
      } else {
        setProducts(sortedProducts);
        handleFilter();
      }
    } catch (e) {
      console.error('error in search', e)
    }
  }

    const handleFilter = async () => {
      try {
        let keywordNames = list.map((item) => item.name?.toLowerCase());
        let platformIds = checkedPlatforms.map((item) => item.id);
        let allProducts = fetchedProducts;
        let kfilter;
        let pfilter;
        let dateSort;

        // Filter by keywords
        if (keywordNames.length !== 0) {
          kfilter = allProducts.filter((item) =>
            keywordNames.includes(item.keyword.toLowerCase())
          );
        } else {
          kfilter = allProducts;
        }

        // Filter by platform IDs
        if (platformIds.length !== 0) {
          pfilter = kfilter.filter((item) =>
            platformIds.includes(item.platform_id)
          );
        } else {
          pfilter = kfilter;
        }

        //console.log("datefilter", pfilter.map((item) => item.date_detected));

        let startDate = new Date(dateFilter.startDate);
        let endDate = new Date(dateFilter.endDate);
        //console.log("DATE RANGE", startDate, endDate);
        endDate.setHours(23, 59, 59, 999);


        dateSort = await pfilter.filter((item) => {
          let date = new Date(item.date_detected);
          if (date >= startDate && date <= endDate) {
            return true;
          }
        })
        //console.log("dateSort", dateSort)


        //console.log("Filtered Products:", dateSort);
        setProducts(dateSort);
        setSortedProducts(dateSort);


      } catch (err) {
        console.error("Error in handleFilter:", err);
      }
    };

  useEffect(() => {
    handleFilter();
  }, [list, checkedPlatforms, fetchedProducts, dateFilter]);




  return (
    <Tabs colorScheme='messenger' pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <TabList>
        <Tab border='transparent'>Detected Products</Tab>
        <Tab border='transparent' onClick={() => fetchProductsByStatus('W')}>Whitelist</Tab>

      </TabList>
      <TabPanels>
        <TabPanel>


          <Box >
            {/* Main Fields */}

            <Card p='0px' w='100%'>

              <Flex
                align={{ sm: "flex-start", lg: "center" }}
                justify='space-between'
                w='100%'
                px='22px'
                py='18px'>
                <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />
                <Button variant='action' alignSelf='right' onClick={() => { handleSaveChanges() }} >Save Changes</Button>
              </Flex>
              {products.slice(0, productsToShow).map((prod, index) => {
                return (
                  <HistoryItem
                    key={index}
                    index={index}
                    product={prod}
                    handleInsertSeller={handleInsertSeller}
                    removeFromInsert={removeFromInsert}
                    statusUpdate={statusUpdate}
                    setStatusUpdate={setStatusUpdate}
                    fetchProducts={fetchProducts}
                    keywordOptions={keywordOptions}
                  />
                )
              })}

            </Card>
            <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
              {successAlert !== '' &&
                <Alert status='success' variant='left-accent' w='40vw'  >
                  <AlertIcon />
                  {successAlert}

                </Alert>
              }
              {failureAlert !== '' &&
                <Alert status='error' variant='left-accent' w='40vw'>
                  <AlertIcon />
                  {failureAlert}
                </Alert>
              }
            </Stack>
            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
              Show less...
            </Text></Link>
            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
              Show more...
            </Text></Link>

            {/* Delete Product */}
          </Box>
        </TabPanel>
        <TabPanel>
          <WhitelistedProduct userId={userInfo.message.result.id} products={productByStatus} fetchProductsByStatus={fetchProductsByStatus} />
        </TabPanel>

      </TabPanels>
    </Tabs>

  );
}
