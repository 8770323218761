import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Image,
  Tooltip,
  Icon,
  Link
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdBlockFlipped, MdCancel, MdCheckCircle, MdOutlineError, MdOutlineWatchLater, MdShoppingCart } from "react-icons/md";

// Custom components
import Card from "components/card/Card";
import Menu from "../components/MainMenu";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';

import { useListContext } from '../../../../contexts/listContext';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';



export default function CheckTable(props) {
  const [sellers, setSellers] = useState([]);
  const { list, checkedPlatforms, dateFilter } = useListContext();
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [extraFilter, setExtraFilter] = useState([]);
  const [sortedSellers, setSortedSellers] = useState([]);
  const [searchedSellers, setSearchedSellers] = useState([]);



  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const fetchKeywords = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/sellers/getSellerDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();

      if (response.ok) {
        setSellers(json.message);
        //console.log('sellers', json)
      } else if (response.status === 400) {
        //console.log(json);
        alert(json.message);
        // handleFailureAlert("Error  getting keywords");
      }
    } catch (e) {
      //console.log("error in getkeywords");
      // handleFailureAlert("Error  getting keywords");

    }
  };

  useEffect(() => {
    fetchKeywords();
  }, [userInfo?.message?.result.id]);

  const handleFilter = async () => {
    try {
      let keywordNames = list.map((item) => item.name?.toLowerCase());
      let platformIds = checkedPlatforms.map((item) => item.id);
      let allProducts = sellers;
      let kfilter;
      let pfilter;
      let dateSort;
      let e1filter;
      let e2filter;
      let e3filter;
      let e4filter;
      let e5filter;
      let e6filter;
      if (keywordNames.length !== 0) {
        kfilter = allProducts.filter((item) =>
          keywordNames?.includes(item.keyword)
        )
      } else {
        kfilter = allProducts;
      }
      if (platformIds.length !== 0) {
        pfilter = kfilter.filter((item) =>
          platformIds?.includes(item.platform_id)
        )
      } else {
        pfilter = kfilter;
      }
      if (extraFilter.includes(1)) {
        e1filter = pfilter.filter((item) =>
          item.seller_status === "W"
        );
      } else {
        e1filter = pfilter;
      }
      if (extraFilter.includes(2)) {
        e2filter = e1filter.filter((item) =>
          item.test_purchase_status === 3
        )
      } else {
        e2filter = e1filter;
      }
      if (extraFilter.includes(3)) {
        e3filter = e2filter.filter((item) =>
          item.test_purchase_status === 4
        )
      } else {
        e3filter = e2filter;
      } if (extraFilter.includes(4)) {
        e4filter = e3filter.filter((item) =>
          item.seller_status === "S"
        )
      } else {
        e4filter = e3filter
      } if (extraFilter.includes(5)) {
        e5filter = e4filter.filter((item) =>
          item.test_purchase_status == null
        )
      } else {
        e5filter = e4filter
      }
      setSearchedSellers(e5filter);
      setSortedSellers(e5filter);
      // setFilteredSellers(e5filter);
    } catch (err) {
      //console.log('error in handleFilter', err)
    }
  }

  useEffect(() => {
    handleFilter();
  }, [list, checkedPlatforms, sellers, extraFilter]);


  const handleSearch = async (text) => {
    try {
      // setProducts(fetchedProducts);
      if (text) {
        setSearchedSellers(sortedSellers.filter((item) =>
          item.seller_name?.toLowerCase().includes(text?.toLowerCase()) ||
          item.keyword?.toLowerCase().includes(text?.toLowerCase())
        ));
      } else {
        setSearchedSellers(sortedSellers);
      }
    } catch (e) {
      console.error('error in search', e)
    }
  }


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <SearchBar m="10px" mt="0px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />

        <Menu extraFilter={extraFilter} setExtraFilter={setExtraFilter} />
      </Flex>
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          <Tr >
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Sr.No.
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Seller Name
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Seller Status
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Platform
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Keyword
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Test Results
              </Flex>
            </Th>
            <Th
              pe='10px'
              borderColor={borderColor}>
              <Flex
                justify='space-between'
                align='center'
                fontSize={{ sm: "10px", lg: "12px" }}
                color='gray.400'>
                Date
              </Flex>
            </Th>

          </Tr>
        </Thead>
        <Tbody>
          {searchedSellers?.map((seller, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>

                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>

                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {seller.seller_name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Tooltip label={seller.seller_status === "W" ?
                      "Official Seller" : "Suspicious Seller"}>
                      <Text color={textColor} fontSize='20px' fontWeight='700'>
                        {seller.seller_status === "W" ?
                          <Icon color='green' as={MdCheckCircle} />
                          : <Icon color='gold' as={MdOutlineError} />}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Td>

                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Tooltip label={() => {
                    switch (seller.platform_id) {
                      case 1:
                        return "Amazon";
                      case 2:
                        return "Flipkart";
                      case 3:
                        return "Snapdeal";
                      case 4:
                        return "Meesho";
                      case 5:
                        return "Glowroad";
                    }
                  }}>
                    <Flex align='center'>

                      {seller.platform_id === 1 && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Amazon}
                        alt='Amazon Icon'
                        mr='5px'
                      />}
                      {seller.platform_id === 2 && <Image
                        boxSize='19px'
                        objectFit='cover'
                        src={Flipkart}
                        alt='flipkart Icon'
                        mr='5px'
                      />}
                      {seller.platform_id === 3 && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Snapdeal}
                        alt='snapdeal Icon'
                        mr='2px'
                      />}
                      {seller.platform_id === 4 && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Meesho}
                        alt='"Meesho" Icon'
                        mr='2px'
                      />}
                      {seller.platform_id === 5 && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Glowroad}
                        alt='Glowroad Icon'
                        mr='2px'
                      />}
                    </Flex>
                  </Tooltip>

                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Link href={seller.prod_link} textDecoration='underline' isExternal>
                    <Tooltip label={seller.title}>
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {seller.keyword}
                        </Text>
                      </Flex>
                    </Tooltip>

                  </Link>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Tooltip label={seller.test_purchase_status == null ? "Not tested" :
                      (seller.test_purchase_status === 3) ? "Genuine Product"
                        : (seller.test_purchase_status === 4) ? "Counterfeit"
                          : "Order in Progress"}>
                      <Text color={textColor} fontSize="20px" fontWeight='700'>
                        {seller.test_purchase_status == null ?
                          <Icon as={MdOutlineWatchLater} /> :
                          (seller.test_purchase_status === 3) ? <Icon color='green' as={MdCheckCircle} />
                            : (seller.test_purchase_status === 4) ? <Icon color='red' as={MdBlockFlipped} />
                              : <Icon color='gold' as={MdShoppingCart} />}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Td>

                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>

                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {seller.date_test ? new Date(seller.date_test).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      }) : ''}
                    </Text>
                  </Flex>
                </Td>
                {/* {
                  "seller_name": "Assiduus Pvt Ltd",
                "seller_status": "W",
                "platform_id": 1,
                "platform_name": "amazon",
                "seller_hash": "53a46e2filter6166384ec6f3941ce76528451",
                "test_purchase_status": 3,
                "product_details_id": 9384,
                "date_test": "2024-04-25T18:30:00.000Z",
                "keyword_id": 15,
                "title": "Candid Prickly Boric Acid, Zinc Oxide, Menthol, Neem Extract, Potassium Alu, Sulphate, Talc, Starch,",
                "keyword": "candid"
    } */}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
