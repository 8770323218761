import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Image,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Lorem,
  Box,
  Tooltip,
  Link
} from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useAuthContext } from "hooks/useAuthContext";
import { useState, useEffect } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import Dropzone from "./Dropzone";
import { MdUpload } from "react-icons/md";
import * as XLSX from 'xlsx';





export default function CheckTable() {
  const { user } = useAuthContext();
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const [newProductInfodata, setNewProductInfodata] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [info, setInfo] = useState([]);
  const [inputKeyword, setInputKeyword] = useState('');
  const [inputManu, setInputManu] = useState('');
  const [inputBrand, setInputBrand] = useState('');
  const [inputError, setInputError] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [insertKeywords, setInsertKeyword] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [newEditMode, setNewEditMode] = useState({});
  const [deleteIds, setDeleteIds] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [productsToShow, setProductsToShow] = useState(20);
  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }


  const fetchProductInfo = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/product_info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();
      // //console.log("json",json);
      if (response.ok) {
        setInfo(json.message);

        //console.log('product info added')
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Error  getting keywords");
      }
    } catch (e) {
      //console.log("error in getProductInfo", e);
      handleFailureAlert("Error  getting keywords");

    }
  };
  const fetchPlatforms = async () => {
    try {
      const response = await fetch('https://anticounterfeit.co.in/api/platforms/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await response.json();
      if (response.ok) {
        setPlatforms(json);
        platforms.forEach((pf) => {
          setSelectedPlatforms[pf.id] = 'X';
        })
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json);
        handleFailureAlert("Error Fetching Platforms");
      }
    } catch (e) {
      //console.log("error in getPlatforms");
      handleFailureAlert("Error Fetching Platforms");

    }
  };



  useEffect(() => {

    fetchProductInfo();
  }, [userInfo?.message?.result.id]);

  const headings = ['SR NO.', 'Brand', 'Category 1', 'Category 1', 'Size', 'MRP']

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleTempInsertKeyword = async () => {
    try {
      if (inputBrand === '' || inputKeyword === '' || inputManu === '' || selectedPlatforms.length === 0) {
        setInputError(true);
        return;
      } else if (inputBrand !== '' & inputKeyword !== '' & inputManu !== '') {
        setInputError(false);
      }
      //console.log(inputKeyword, inputManu, inputBrand, selectedPlatforms);
      let dataToSend = {
        user_id: userInfo.message.result.id,
        name: inputKeyword,
        manufacturer: inputManu,
        brand: inputBrand,
        platformIds: selectedPlatforms
      }
      await setInsertKeyword([...insertKeywords, dataToSend]);
      await setInputManu('');
      await setInputKeyword('');
      await setInputBrand('');
      // Reset the form fields
      // setSelectedPlatforms([]);

    } catch (err) {
      //console.log(err);
    }
  }

  // const insert = async (data) => {
  //   try {
  //     const response = await fetch('https://anticounterfeit.co.in/api/keyword/insert', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         keywords: data
  //       })

  //     });

  //     const json = await response.json();

  //     if (response.ok) {
  //       //console.log(json)
  //       // alert("Changes Saved");
  //       handleSuccessAlert("Changes Saved");
  //       fetchKeywords();
  //     } else if (response.status === 400) {
  //       //console.log(json);
  //       // alert("error in insertion", json);
  //       handleFailureAlert("error in insertion");
  //     }
  //   } catch (err) {
  //     alert('error in insertion');
  //     //console.log('err');
  //     handleFailureAlert("error in insertion");

  //   }
  // }

  const handleConfirm = async () => {
    if (insertKeywords === null) {
      handleSuccessAlert('No Changes to save');
      return;
    }
    insert(insertKeywords);
  }


  const productsPerPage = 20;

  const loadMore = () => {
    setProductsToShow(productsToShow + productsPerPage);
  };
  const loadLess = () => {
    setProductsToShow(productsToShow - productsPerPage);
  };


  const handleNewDeleteButton = async (row) => {
    try {
      setInsertKeyword(insertKeywords.filter((item) => (item.name !== row.name && item.manufacturer !== row.manufacturer && item.brand !== row.brand)));

    } catch (err) {
      //console.log('error in deleting', err);
    }
  }




  const handleFileUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        let arrayBuffer = e.target.result;
        let workbook = XLSX.read(arrayBuffer, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let headers = jsonData[0];
        let filteredRows = jsonData.slice(1).map((row) => {
          let rowData = {
            user_id: userInfo.message.result.id,
            brand_name: row[headers.indexOf('brand_name')],
            category_1: row[headers.indexOf('category_1')],
            category_2: row[headers.indexOf('category_2')],
            size: row[headers.indexOf('size')],
            mrp: row[headers.indexOf('mrp')],
          };
          return rowData;
        });
        setNewProductInfodata(filteredRows);
        //console.log('filteredData', filteredRows);
      };
      reader.readAsArrayBuffer(file);
      handleSuccessAlert("file uploaded successfully")
    }
  };

  const handleSaveModal = async () => {
    try {
      const response = await fetch('https://abusecheck.anticounterfeit.co.in/add_products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          newProductInfodata
        )
      });

      const json = await response.json();
      // //console.log("json",json);
      if (response.ok) {
        // setInfo(json.message);
        handleSuccessAlert("Product added successfully")
        fetchProductInfo();
        //console.log('product info added')
      } else if (response.status !== 200) {
        //console.log(json);
        // alert(json.message);
        fetchProductInfo();
        handleFailureAlert("Error  saving product info");
      }
    } catch (err) {
      console.error("error in modal save button ", err);
    }
  }

  const handleSaveChanges = async () => {
    try {
      const confirm = window.confirm('Are you sure')
      if (!confirm) return;
      //console.log('deleting  ids==>', deleteIds);
      const response = await fetch('https://abusecheck.anticounterfeit.co.in/remove_index', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          deleteIds
        )


      });

      const json = await response.json();

      if (response.ok) {
        //console.log(json);
        // alert('Successfully deleted the Keyword');
        handleSuccessAlert("Keyword Deleted");
        fetchProductInfo();
      } else if (response.status === 400) {
        //console.log(json);
        // alert("error in insertion", json);
        handleFailureAlert("Error in deletion");
        fetchProductInfo();

      }
    } catch (err) {
      // alert('error in insertion');
      handleFailureAlert("Error in deletion");
      //console.log('err');
    }
  }
  const handleToggleDelete = (id) => {
    // Toggle the state of toggleDelete
    setToggleDelete(!toggleDelete);

    if (!toggleDelete) {
      // If toggleDelete is false (initially or toggled to false), add the id to deleteIds
      setDeleteIds([...deleteIds, id]);
      //console.log("deleteIds", deleteIds);
    } else {
      // If toggleDelete is true, remove the id from deleteIds
      const updatedDeleteIds = deleteIds.filter((deleteId) => deleteId !== id);
      setDeleteIds(updatedDeleteIds);
    }
  };



  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowY='hidden'
      overflowX={{ sm: "scroll", lg: "hidden" }} mb='20px'>
      <Flex
        align={{ sm: "flex-start", lg: "center" }}
        direction={{ md: "row", sm: "column" }}
        justify='space-between'
        w='100%'
        px='22px'
        py='10px'>
        {/* <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} /> */}
        <Flex flexDir='row' alignSelf='right' display={{ base: 'flex-start', lg: 'center' }}>
          {/* <Button variant='action' mr="5px" onClick={() => { handleAbuseCheck() }} >Check Abuse</Button> */}
          <Button variant='action' mr="5px" onClick={onOpen} >Upload Data</Button>
          <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Upload Data</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontWeight='bold' mb='1rem'>
                  Instructions to add excel sheet
                </Text>
                *Upload a .xlsx file using below given input space
                <br />
                *The file should contain the following columns
                <br />
                *brand_name | category_1 | category_2 | size | mrp
                <br />
                *The first row must consist of the headings
                <br />
                <Dropzone
                  w={{ base: "100%", "2xl": "268px" }}
                  mt='36px'
                  maxH={{ base: "60%", lg: "60%", "2xl": "100%" }}
                  minH={{ base: "60%", lg: "60%", "2xl": "100%" }}
                  onFilesUploaded={handleFileUpload}
                  content={
                    <Box>
                      <Icon as={MdUpload} w='40px' h='40px' color={brandColor} />
                      <Flex justify='center' mx='auto' mb='12px' >
                        <Text fontSize='xl' fontWeight='700' color={brandColor}>
                          Upload Files
                        </Text>
                      </Flex>
                      <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                        Only .xlsx Sheet
                      </Text>
                    </Box>
                  }
                />
              </ModalBody>
              <ModalFooter>
                <Button variant='action' mr={2} onClick={handleSaveModal}>
                  Save Products
                </Button>
              </ModalFooter>

            </ModalContent>
          </Modal>
          <Button variant='action' ml="5px" onClick={() => { handleSaveChanges() }} >Save Changes</Button>
        </Flex>
      </Flex>

      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {/* {headerGroups.map((headerGroup, index) => ( */}
          <Tr  >
            {headings.map((column, index) => (
              <Th

                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {info?.slice(0, productsToShow).map((row, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.brand_name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.category_1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.category_2}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.size}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.mrp}
                    </Text>
                  </Flex>
                </Td>


                {/* {!editMode[row.id] ?
                  <Td
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    <Flex align='center'>
                      {row.crawl_status_amazon !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Amazon}
                        alt='Amazon Icon'
                        mr='5px'
                      />}
                      {row.crawl_status_flipkart !== 'X' && <Image
                        boxSize='19px'
                        objectFit='cover'
                        src={Flipkart}
                        alt='flipkart Icon'
                        mr='5px'
                      />}
                      {row.crawl_status_snapdeal !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Snapdeal}
                        alt='snapdeal Icon'
                        mr='2px'
                      />}
                    </Flex>
                  </Td> :
                  <Td>
                    <Stack pl={6} mt={1} spacing={1} >
                      <Checkbox
                        isChecked={selectedPlatforms.length === platforms.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const allPlatformIds = platforms.map((platform) => platform.id);
                            setSelectedPlatforms(allPlatformIds);
                          } else {
                            setSelectedPlatforms([]);
                          }
                        }}
                      >
                        ALL
                      </Checkbox>
                      {platforms.map((platform, index) => {
                        return (
                          <Checkbox
                            key={index}
                            isChecked={selectedPlatforms.includes(platform.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedPlatforms([...selectedPlatforms, platform.id]);
                              } else {
                                setSelectedPlatforms(selectedPlatforms.filter((id) => id !== platform.id));
                              }
                            }}
                          >
                            {platform.platform_name}
                          </Checkbox>
                        )
                      })}

                    </Stack>
                  </Td>
                } */}
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Tooltip label='Delete Product'>
                      <Button colorScheme="gray" fontSize="20px" p='0px' onClick={() => handleToggleDelete(row.id)}>
                        <Icon color={deleteIds.includes(row.id) === true ? 'red' : 'gray'} as={DeleteIcon} />
                      </Button>
                    </Tooltip>

                  </Flex>
                </Td>
              </Tr>

            );
          })}
          {/* {insertKeywords.map((row, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {keywords.length + index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.manufacturer}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.brand}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    {row.platformIds.includes(1) && <Image
                      boxSize='15px'
                      objectFit='cover'
                      src={Amazon}
                      alt='Amazon Icon'
                      mr='5px'
                    />}
                    {row.platformIds.includes(2) && <Image
                      boxSize='19px'
                      objectFit='cover'
                      src={Flipkart}
                      alt='flipkart Icon'
                      mr='5px'
                    />}
                    {row.platformIds.includes(3) && <Image
                      boxSize='15px'
                      objectFit='cover'
                      src={Snapdeal}
                      alt='snapdeal Icon'
                      mr='2px'
                    />}
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "150px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Button colorScheme="gray" onClick={() => handleNewDeleteButton(row)}>
                      <Icon as={DeleteIcon} />
                    </Button>
                  </Flex>
                </Td>
              </Tr>

            );
          })} */}
          {/* <Tr>
            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "150px", lg: "auto" }}
              borderColor='transparent'>

            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "100px", md: "150px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputKeyword(e.target.value)}
                  // variant='auth'
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Keyword'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputKeyword === '' && inputError ? 'crimson' : undefined}
                  value={inputKeyword}
                />
              </Flex>
            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputManu(e.target.value)}
                  // variant='auth'
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Manufacturer'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputManu === '' && inputError ? 'crimson' : undefined}
                  value={inputManu}
                />
              </Flex>
            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputBrand(e.target.value)}
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Brand'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputBrand === '' && inputError ? 'crimson' : undefined}
                  value={inputBrand}
                />
              </Flex>
            </Td>
            <Td></Td>
            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "100px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Button colorScheme='messenger' size='md' onClick={handleTempInsertKeyword}>
                Add+
              </Button>
            </Td>
          </Tr>

          <Tr
            fontSize={{ sm: "14px" }}
            minW={{ sm: "150px", md: "200px", lg: "auto" }}
            borderColor='transparent'>
            <Td>&nbsp;</Td>
            <Td colSpan={3} pl='0px' pt='-10px'>
              <Stack pl={6} mt={1} spacing={1} direction='row'>
                <Checkbox
                  isChecked={selectedPlatforms.length === platforms.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const allPlatformIds = platforms.map((platform) => platform.id);
                      setSelectedPlatforms(allPlatformIds);
                    } else {
                      setSelectedPlatforms([]);
                    }
                  }}
                >
                  ALL
                </Checkbox>
                {platforms.map((platform, index) => {
                  return (
                    <Checkbox
                      key={index}
                      isChecked={selectedPlatforms.includes(platform.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPlatforms([...selectedPlatforms, platform.id]);
                        } else {
                          setSelectedPlatforms(selectedPlatforms.filter((id) => id !== platform.id));
                        }
                      }}
                    >
                      {platform.platform_name}
                    </Checkbox>
                  )
                })}

              </Stack>
            </Td>
            <Td>
              <Flex align='center' w='100%' display={inputError ? '' : 'none'}>
                <Text color='red' fontSize='md' fontWeight='700'>
                  Empty Fields
                </Text>
              </Flex>
            </Td>
          </Tr> */}
          {/* <Tr bottom="0px" pos="absolute" right='10px'>
            <Td colSpan={4} borderColor='transparent'></Td>

            <Td colSpan={2} borderColor='transparent' right="10px">
              <Button colorScheme='messenger' size='md' onClick={handleConfirm}>
                Save Changes
              </Button>
            </Td>
          </Tr> */}

        </Tbody>
      </Table>
      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
        Show less...
      </Text></Link>
      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
        Show more...
      </Text></Link>
      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'  >
            <AlertIcon />
            {successAlert}

          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>

    </Card>
  );
}
