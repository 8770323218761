import React, { useState, useEffect } from 'react'
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Text,
  useSafeLayoutEffect,
  Stack,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import Card from "components/card/Card.js";

// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import TestPie from "views/admin/default/components/TestPie";

import Tasks from "views/admin/default/components/Tasks";
import SuspectedBrandsTable from "views/admin/default/components/SuspectedBrandsTable";
import SuspectedSellers from "views/admin/default/components/SuspectedSellers";
import TestPurchaseTable from "views/admin/default/components/TestPurchaseTable";
import InfringingPlatforms from "views/admin/default/components/InfringingPlatforms";
import BarChart from "views/admin/default/components/BarChart";
import BrandAnalysisTable from "views/admin/default/components/BrandAnalysisTable";
import PlatformAnalysisTable from "views/admin/default/components/PlatformAnalysisTable";
import { useSuspiciousProductContext } from "contexts/ProductContext";
import { useListContext } from "../../../contexts/listContext";


// import UserActivity from './components/UserActivity';

export default function UserReports() {
  // Chakra Color Mode
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { suspiciousProducts, setSuspiciousProducts } = useSuspiciousProductContext();
  const { list, checkedPlatforms, abuse_type, dateFilter, setDateFilter } = useListContext();

  const [topSuspectedBrands, setTopSuspectedBrands] = useState([]);
  const [topSuspectedSellers, setTopSuspectedSellers] = useState([]);
  const [topInfringingPlatforms, setTopInfringingPlatforms] = useState([]);
  const [brandWise, setBrandWise] = useState([]);
  const [platformWise, setPlatformWise] = useState([]);
  const [test, setTest] = useState([]);

  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }

  const fetchTopSuspectedBrand = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/topSuspectedBrands', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('TopSuspectedBrands fetched')
        setTopSuspectedBrands(json.data)
        //console.log("topSuspectedBrands", json.data);
      } else if (response.status !== 400) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in fetchProducts");
    }
  };

  const fetchTopSuspectedSellers = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/topSuspectedSellers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('TopSuspectedSellers fetched')
        setTopSuspectedSellers(json.data)
        //console.log("TopSuspectedSellers", json.data);
      } else if (response.status !== 400) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in setTopSuspectedSellers");
    }
  };

  const fetchTopInfringingPlatforms = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/topInfringingPlatforms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('TopInfringingPlatforms fetched')
        setTopInfringingPlatforms(json.data)
        //console.log("TopInfringingPlatforms", json.data);
      } else if (response.status !== 400) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in TopInfringingPlatforms");
    }
  };

  const fetchBrandWise = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/brandWise', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('setBrandWise fetched')
        setBrandWise(json.data)
        //console.log("setBrandWise", json.data);
      } else if (response.status !== 400) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in setBrandWise");
    }
  };

  const fetchPlatformWise = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/platformWise', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('PlatformWise fetched')
        setPlatformWise(json.data)
        //console.log("PlatformWise", json.data);
      } else if (response.status !== 400) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in PlatformWise");
    }
  };

  const fetchTest = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/report/testCount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          durationType: dateFilter
        })
      });
      const json = await response.json();
      if (response.ok) {
        //console.log('testCount fetched')
        setTest(json.data)
        //console.log("testCoutn", json.data);
      } else if (response.status !== 200) {
        //console.log(json);
        //console.log("Error Fetching Data");
        handleFailureAlert("Error fetching Data");
      }
    } catch (e) {
      //console.log("error in testCount", e);
    }
  };

  useEffect(() => {

    fetchTopSuspectedBrand();
    fetchTopSuspectedSellers();
    fetchTopInfringingPlatforms();
    fetchBrandWise();
    fetchPlatformWise();

    fetchTest();
  }, [userInfo?.message?.result.id, dateFilter]);


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>


      <Heading > <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='32px'
          fontWeight='700'
          lineHeight='100%'
          m='auto'
        >
          {/* OVERVIEW */}
        </Text>
      </Flex>
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <SuspectedBrandsTable
          finalData={topSuspectedBrands}
        />
        <PieCard finalData={topSuspectedBrands} />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <SuspectedSellers
          finalData={topSuspectedSellers}
        />
        <PieCard finalData={topSuspectedSellers} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <InfringingPlatforms
          finalData={topInfringingPlatforms}
        />
        <PieCard finalData={topInfringingPlatforms} />
      </SimpleGrid>
      <Heading > <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='32px'
          fontWeight='700'
          lineHeight='100%'
          m='auto'
        >
          DETAIL ANALYSIS
        </Text>
      </Flex>
      </Heading>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <BrandAnalysisTable
          finalData={brandWise}
        />
        <BarChart
          finalData={brandWise}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <PlatformAnalysisTable
          finalData={platformWise}
        />
        <BarChart
          finalData={platformWise}
        />
      </SimpleGrid>
      <Heading > <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='32px'
          fontWeight='700'
          lineHeight='100%'
          m='auto'
        >
          Test Purchase
        </Text>
      </Flex>
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TestPurchaseTable
          finalData={test}
        />
        <TestPie finalData={test} />
      </SimpleGrid>


      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <CounterfeitSellerTable
          fetchedProducts={suspiciousProducts}
        />
      </SimpleGrid> */}


      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'  >
            <AlertIcon />
            {successAlert}

          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>
    </Box>
  );
}
