
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Alert,
  AlertIcon
} from "@chakra-ui/react";

// Custom components
import SuspiciousItem from "views/admin/suspicious/components/SuspiciousItem";
import WhitelistedProduct from 'views/admin/suspicious/components/WhitelistedProduct';
import BlackListedProduct from 'views/admin/suspicious/components/TakenDown';
import NoneAbuseProducts from 'views/admin/suspicious/components/NoneAbuseProducts';

import Card from "components/card/Card.js";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useListContext } from '../../../contexts/listContext';
import { useSuspiciousProductContext } from "../../../contexts/ProductContext";



export default function Products() {

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const [productsToShow, setProductsToShow] = useState(20); // Initial number of products to show
  const productsPerPage = 20; // Number of products to load per page


  const [productByStatus, setProductByStatus] = useState([]);
  const [insertSeller, setInsertSeller] = useState([]);
  const [whiteListSeller, setWhiteListSeller] = useState([]);
  const [reportedSeller, setReportedSeller] = useState([]);
  const [blackListSeller, setBlackListSeller] = useState([]);
  const [testProduct, setTestProduct] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const { list, checkedPlatforms, abuse_type, dateFilter } = useListContext();
  const { suspiciousProducts, setSuspiciousProducts } = useSuspiciousProductContext();
  const [abuseTypes, setAbuseTypes] = useState([]);
  const [abuseCulprit, setAbuseCulprit] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);

  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }



  const fetchProductsByStatus = async (status) => {
    try {
      // setProductByStatus([]);
      setFetchedProducts([]);
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/products/byStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          status: status
        })
      });

      const json = await response.json();

      if (response.ok) {
        // setProductByStatus(json);
        // setFetchedProducts(json);
        if (status === 'S') {
          setSuspiciousProducts(json);
        }
        // setProductByStatus(json);
        setFetchedProducts(json);

        //console.log('status Products fetched')
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Error Fetching Data");
      }
    } catch (e) {
      //console.log("error in fetchProducts");
    }
  };


  const loadMore = () => {
    setProductsToShow(productsToShow + productsPerPage);
  };
  const loadLess = () => {
    setProductsToShow(productsToShow - productsPerPage);
  };

  useEffect(() => {
    fetchProductsByStatus('S');
  }, [userInfo?.message?.result.id]);

  const handleUpdateSeller = async (product, seller_status) => {
    try {
      const existingIndex = insertSeller.findIndex(item => item.product.psm_id === product.psm_id);

      if (existingIndex !== -1) {
        // Product already exists, update its status
        setInsertSeller(prev => {
          const updatedSeller = [...prev];
          updatedSeller[existingIndex].seller_status = seller_status;
          return updatedSeller;
        });
      } else {
        // Product does not exist, add it to the list
        setInsertSeller([...insertSeller, { product, seller_status }]);
      }
      //console.log('updateSeller', insertSeller);
    } catch (err) {
      console.error('err in handleInserSeller', err)
    }
  }

  const removeFromUpdate = async (product, seller_status) => {
    setInsertSeller(insertSeller.filter((item) => {
      return !(item.product.psm_id === product.psm_id);
    }));
    //console.log('removed from list new Update Seller:', insertSeller);
  }
  const getAbuseTypes = async () => {
    try {
      const response = await fetch('https://anticounterfeit.co.in/api/abuses/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

      });

      const json = await response.json();

      if (response.ok) {
        //console.log("abuses fetched", json);
        setAbuseTypes(json);
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Error fetching Abuse Types");
      }
      // setStatusUpdate(true)
    } catch (e) {
      //console.log("error in getAbuseTypes", e);
    }
  }

  const handleInsertAbuse = async (product, abuse_type) => {
    try {
      const existingIndex = abuseCulprit.findIndex(item => item.product.psm_id === product.psm_id);

      if (existingIndex !== -1) {
        // Product already exists, update its status
        setAbuseCulprit(prev => {
          const updated = [...prev];
          updated[existingIndex].abuse_type = abuse_type;
          return updated;
        });
      } else {
        // Product does not exist, add it to the list
        setAbuseCulprit([...abuseCulprit, { product, abuse_type }]);
      }
      //console.log('abused', abuseCulprit);
    } catch (err) {
      console.error('err in handleInserAbuse', err)
    }
  }
  const handleSaveChanges = async () => {
    try {
      //console.log(insertSeller);
      const uid = JSON.parse(localStorage.getItem("PAuser")).message.result.id;
      const response = await fetch('https://anticounterfeit.co.in/api/sellers/updateSellerStatus/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sellers: insertSeller,
          uid: uid
        })
      });

      const json = await response.json();

      if (response.ok) {
        handleSuccessAlert("Changes Saved.");
        fetchProductsByStatus('S');
        //console.log('Empty insert seller', insertSeller)
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Failed to Save Changes. Please try again!");
      }
      setStatusUpdate(true)


      const abuseTypeSave = abuseCulprit;
      //console.log("abuseTypeSave", abuseTypeSave);
      // if (abuseTypeSave.length!==0) {
      const abused = abuseTypeSave.map((item) => ({
        psm_id: item.product.psm_id,
        abuse_type: item.abuse_type
      }));
      const response1 = await fetch('https://anticounterfeit.co.in/api/products/reportAbuse/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          products: abused
        })
      });
      const json1 = await response1.json();
      //console.log("checkpoint", response1);

      if (response1.ok) {
        //console.log("Abuse TYPE Updated");
        await fetchProductsByStatus('S');
        // await handleFilter();
        handleSuccessAlert("Changes Saved!");
        //console.log('Empty abuse Culprit', abuseTypeSave);
      } else if (response.status === 400) {
        //console.log(json1);
        //console.log('error in report abuse', json1.message);
        handleFailureAlert("Failed to Save Changes. Please try again!");
      }
      // }
    } catch (e) {
      //console.log("error in handleSavechanges", e);
    }
  }

  const handleAbuseCheck = async () => {
    try {
      handleSuccessAlert("Abuse Checking triggered");
      const uid = JSON.parse(localStorage.getItem("PAuser")).message.result.id;
      const resp = await fetch('https://anticounterfeit.co.in/api/abuses/abuseCheck', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: uid
        })
      })
      const json = await resp.json();
      fetchProductsByStatus('S');
      if (resp.ok) {
        handleSuccessAlert("Listings Abuse check done")

      } else {
        handleFailureAlert("Some issues occurred we're looking into it");
        alert(json.error);
      }

    } catch (err) {
      console.error("error in handleAbuseCheck", err)
    }
  }

  const handleFileComplaint = async () => {
    try {
      //console.log(insertSeller);
      const prod_codes = insertSeller.map((item) => {
        if (item.product.prod_code && item.seller_status === 'R')
          return item.product.prod_code;
      });
      //console.log("prodcodes", prod_codes);
      const uid = JSON.parse(localStorage.getItem("PAuser")).message.result.id;
      const response = await fetch('https://anticounterfeit.co.in/api/form/flipkartInfringementForm/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          prod_code: prod_codes,
          uid: uid
        })
      });

      const json = await response.json();
      if (response.ok) {
        handleSuccessAlert("Changes Saved.");
        // fetchProductsByStatus('S');
        // //console.log('Empty insert seller', insertSeller)
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Failed to file complaint, Please try again!");
      }
      setInsertSeller([]);
      setStatusUpdate(true)
    } catch (err) {
      //console.log("err in generating file ", err)
    }
  }


  const handleSearch = async (text) => {
    try {
      // setProducts(fetchedProducts);
      if (text) {
        setProductByStatus(sortedProducts.filter((item) =>
          item.title?.toLowerCase().includes(text?.toLowerCase()) ||
          item.keyword?.toLowerCase().includes(text?.toLowerCase()) ||
          item.seller_name?.toLowerCase().includes(text?.toLowerCase()) ||
          item.prod_code?.toLowerCase().includes(text?.toLowerCase())
        ));
      } else {
        setProductByStatus(sortedProducts);
        handleFilter();
      }
    } catch (e) {
      console.error('error in search', e)
    }
  }

  const handleFilter = async () => {
    try {
      let keywordNames = list.map((item) => item.name?.toLowerCase());
      let platformIds = checkedPlatforms.map((item) => item.id);
      let abuseTypeId = abuse_type.map((item) => item.id);
      let allProducts = fetchedProducts;
      let kfilter;
      let pfilter;
      let afilter;
      let dateSort;
      if (keywordNames.length !== 0) {
        kfilter = allProducts.filter((item) =>
          keywordNames?.includes(item.keyword)
        )
      } else {
        kfilter = allProducts;
      }

      if (platformIds.length !== 0) {
        pfilter = kfilter.filter((item) =>
          platformIds?.includes(item.platform_id)
        )
      } else {
        pfilter = kfilter;
      }

      if (abuseTypeId.length !== 0) {
        afilter = pfilter.filter((item) =>
          abuseTypeId?.includes(item.abuse_type_id) || item.abuse_type_id === null
        )
      } else {
        afilter = pfilter;
      }
      let startDate = new Date(dateFilter.startDate);
      let endDate = new Date(dateFilter.endDate);
      //console.log("DATE RANGE", startDate, endDate);

      dateSort = await afilter.filter((item) => {
        let date = new Date(item.date_detected);
        if (date >= startDate && date <= endDate) {
          return true;
        }
      })
      //console.log("dateSort", dateSort)


      setProductByStatus(dateSort);
      setSortedProducts(dateSort);
    } catch (err) {
      //console.log('error in handleFilter', err)
    }
  }
  useEffect(() => {
    handleFilter();
  }, [list, checkedPlatforms, fetchedProducts, abuse_type, dateFilter]);

  useEffect(() => {
    getAbuseTypes();
  }, []);



  return (
    <Tabs colorScheme='messenger' pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <TabList>
        <Tab border='transparent' onClick={() => { fetchProductsByStatus('S') }}>Suspicious</Tab>
        <Tab border='transparent' onClick={() => { fetchProductsByStatus('S') }}>No Abuse</Tab>
        <Tab border='transparent' onClick={() => { fetchProductsByStatus('R') }}>Reported</Tab>
        <Tab border='transparent' onClick={() => { fetchProductsByStatus('TD') }}>Takendown Listings</Tab>

      </TabList>
      <TabPanels>
        <TabPanel>
          <Box >
            {/* Main Fields */}
            <Card p='0px' w='100%'>
              <Flex
                align={{ sm: "flex-start", lg: "center" }}
                direction={{ md: "row", sm: "column" }}
                justify='space-between'
                w='100%'
                px='22px'
                py='18px'>
                <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />
                <Flex flexDir='row' alignSelf='right' display={{ base: 'flex-start', lg: 'center' }}>
                  <Button variant='action' mr="5px" onClick={() => { handleAbuseCheck() }} >Check Abuse</Button>
                  <Button variant='action' mr="5px" onClick={() => { handleFileComplaint() }} >File Complaint</Button>
                  <Button variant='action' ml="5px" onClick={() => { handleSaveChanges() }} >Save Changes</Button>
                </Flex>
              </Flex>
              {productByStatus
                .filter(prod => prod.abuse_type_id !== 0)
                .slice(0, productsToShow)
                .map((prod, index) => (
                  <SuspiciousItem
                    key={index}
                    index={index}
                    product={prod}
                    handleInsertSeller={handleUpdateSeller}
                    removeFromInsert={removeFromUpdate}
                    statusUpdate={statusUpdate}
                    setStatusUpdate={setStatusUpdate}
                    abuseTypes={abuseTypes}
                    handleInsertAbuse={handleInsertAbuse}
                  />
                ))}


            </Card>

            <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
              {successAlert !== '' &&
                <Alert status='success' variant='left-accent' w='40vw'  >
                  <AlertIcon />
                  {successAlert}

                </Alert>
              }
              {failureAlert !== '' &&
                <Alert status='error' variant='left-accent' w='40vw'>
                  <AlertIcon />
                  {failureAlert}
                </Alert>
              }
            </Stack>

            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
              Show less...
            </Text></Link>
            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
              Show more...
            </Text></Link>

            {/* Delete Product */}
          </Box>
        </TabPanel>
        <TabPanel>
          <NoneAbuseProducts userId={userInfo.message.result.id} products={productByStatus} fetchProductsByStatus={fetchProductsByStatus} />
        </TabPanel>
        <TabPanel>
          <WhitelistedProduct userId={userInfo.message.result.id} products={fetchedProducts} fetchProductsByStatus={fetchProductsByStatus} />
        </TabPanel>
        <TabPanel>
          <BlackListedProduct userId={userInfo.message.result.id} products={fetchedProducts} fetchProductsByStatus={fetchProductsByStatus} />
        </TabPanel>
      </TabPanels>
    </Tabs>

  );
}
