
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
 Grid, GridItem 
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import React from "react";

import CheckTable from "views/admin/sellers/components/CheckTable";

// import Tasks from "views/admin/keyword/components/Tasks";


export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
        <CheckTable />
        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
    </Box>
  );
}
