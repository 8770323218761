// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, Heading } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

// Custom components
import Card from "components/card/Card.js";

import React, { useEffect, useState } from "react";

export default function Conversion(props) {
  const { finalData } = props;

  const pieChartOptions = {
    labels: finalData && finalData.length > 0 ? finalData?.map((it)=>it.brand || it.sellers||it.platform_name||"unknown") : [],
    colors: ["#6ce5e8", "#41b8d5", "#2d8bba", "#2f5f98", "#31356e","#111D4A"],
    chart: {
      width: "70px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
    },

    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ["#6ce5e8", "#41b8d5", "#2d8bba", "#2f5f98", "#31356e","#111D4A"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };
  return (
    <Card p='20px' align='center' w='100%' >
      <ReactApexChart
        options={pieChartOptions}
        series={finalData?.map((it) => it.count)}
        type='donut'
      // width='100%'
      // height='100%'
      />

    </Card>
  );
}
