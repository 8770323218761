
import React, { useEffect, useState } from "react";

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Text,
    useColorModeValue,
    SimpleGrid,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    Stack,
    Alert,
    AlertIcon
} from "@chakra-ui/react";

// Custom components
import ItemTakenDown from "views/admin/suspicious/components/ItemTakenDown";
import Card from "components/card/Card.js";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useListContext } from '../../../../contexts/listContext';




export default function TakenDown(props) {
    const { userId, products, fetchProductsByStatus } = props;
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [productsToShow, setProductsToShow] = useState(20); // Initial number of products to show
    const productsPerPage = 20; // Number of products to load per page
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [updateSeller, setUpdateSeller] = useState([])
    const [searchedProds, setSearchedProds] = useState([]);
    const { list, checkedPlatforms, abuse_type, dateFilter } = useListContext();
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [sortedProducts, setSortedProducts] = useState([]);


    const [successAlert, setSuccesAlert] = useState('');
    const [failureAlert, setFailureAlert] = useState('');

    const handleSuccessAlert = async (alertMessage) => {
        setSuccesAlert(alertMessage);
        setTimeout(() => {
            setSuccesAlert('');
        }, 5000);
    }
    const handleFailureAlert = async (alertMessage) => {
        setFailureAlert(alertMessage);
        setTimeout(() => {
            setFailureAlert('');
        }, 5000);
    }





    // useEffect(() => {
    //     setSearchedProds(products);
    // }, [products]);




    const loadMore = () => {
        setProductsToShow(productsToShow + productsPerPage);
    };
    const loadLess = () => {
        setProductsToShow(productsToShow - productsPerPage);
    };

    const handleUpdateSeller = async (product, seller_status) => {
        try {
            const existingIndex = updateSeller.findIndex(item => item.product.psm_id === product.psm_id);

            if (existingIndex !== -1) {
                // Product already exists, update its status
                setUpdateSeller(prev => {
                    const updatedSeller = [...prev];
                    updatedSeller[existingIndex].seller_status = seller_status;
                    return updatedSeller;
                });
            } else {
                // Product does not exist, add it to the list
                setUpdateSeller([...updateSeller, { product, seller_status }]);
            }
            //console.log('updateSeller', updateSeller);
        } catch (err) {
            console.error('err in handleInserSeller', err)
        }
    }

    const removeFromUpdate = async (product, seller_status) => {
        setUpdateSeller(updateSeller.filter((item) => {
            return !(item.product.psm_id === product.psm_id);
        }));
        //console.log('removed from list new Update Seller:', updateSeller);
    }

    const handleSaveChanges = async () => {
        try {
            //console.log(updateSeller);
            const uid = JSON.parse(localStorage.getItem("PAuser")).message.result.id;

            const response = await fetch('https://anticounterfeit.co.in/api/sellers/updateSellerStatus/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sellers: updateSeller,
                    uid: uid
                })
            });

            const json = await response.json();

            if (response.ok) {
                // alert("Status Updated");
                handleSuccessAlert("Changes Saved");
                await fetchProductsByStatus('TD');
                //console.log('Empty update seller', updateSeller)
            } else if (response.status === 400) {
                //console.log(json);
                // alert(json.message);
                handleFailureAlert("Error Saving Changes. Please try again!");
            }
            setStatusUpdate(true)

        } catch (e) {
            //console.log("error in handleSavechanges");
        }
    }

    const handleSearch = async (text) => {
        try {
            // setProducts(fetchedProducts);
            if (text) {
                setSearchedProds(sortedProducts.filter((item) =>
                    item.title?.toLowerCase().includes(text?.toLowerCase()) ||
                    item.keyword?.toLowerCase().includes(text?.toLowerCase()) ||
                    item.seller_name?.toLowerCase().includes(text?.toLowerCase()) ||
                    item.prod_code?.toLowerCase().includes(text?.toLowerCase())
                ));
            } else {
                setSearchedProds(sortedProducts);
                handleFilter();
            }
        } catch (e) {
            console.error('error in search', e)
        }
    }

    const handleFilter = async () => {
        try {
            let keywordNames = list.map((item) => item.name?.toLowerCase());
            let platformIds = checkedPlatforms.map((item) => item.id);
            let allProducts = products;
            let abuseTypeId = abuse_type.map((item) => item.id);
            let kfilter;
            let pfilter;
            let afilter;
            let dateSort;
            if (keywordNames.length !== 0) {
                kfilter = allProducts.filter((item) =>
                    keywordNames?.includes(item.keyword)
                )
            } else {
                kfilter = allProducts;
            }
            if (platformIds.length !== 0) {
                pfilter = kfilter.filter((item) =>
                    platformIds?.includes(item.platform_id)
                )
            } else {
                pfilter = kfilter;
            }

            if (abuseTypeId.length !== 0) {
                afilter = pfilter.filter((item) =>
                    abuseTypeId?.includes(item.abuse_type_id) || item.abuse_type_id === null
                )
            } else {
                afilter = pfilter;
            }
            let startDate = new Date(dateFilter.startDate);
            let endDate = new Date(dateFilter.endDate);
            //console.log("DATE RANGE", startDate, endDate);

            dateSort = await afilter.filter((item) => {
                let date = new Date(item.date_takendown);
                if (date >= startDate && date <= endDate) {
                    return true;
                }
            })
            //console.log("dateSort", dateSort)

            setSearchedProds(dateSort);
            setSortedProducts(dateSort);

        } catch (err) {
            //console.log('error in handleFilter', err)
        }
    }
    useEffect(() => {
        handleFilter();
    }, [list, checkedPlatforms, products, abuse_type, dateFilter]);



    return (
        <Box >
            {/* Main Fields */}

            <Card p='0px' w='100%'>
                <Flex
                    align={{ sm: "flex-start", lg: "center" }}
                    justify='space-between'
                    w='100%'
                    px='22px'
                    py='18px'>
                    <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />
                    <Button variant='action' alignSelf='right' onClick={() => handleSaveChanges()}>Save Changes</Button>
                </Flex>
                {searchedProds?.slice(0, productsToShow).map((prod, index) => {
                    return (
                        <ItemTakenDown
                            key={index}
                            index={index}
                            product={prod}
                            handleUpdateSeller={handleUpdateSeller}
                            removeFromUpdate={removeFromUpdate}
                            statusUpdate={statusUpdate}
                            setStatusUpdate={setStatusUpdate}
                        />
                    )
                })}

            </Card>
            <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
                {successAlert !== '' &&
                    <Alert status='success' variant='left-accent' w='40vw'  >
                        <AlertIcon />
                        {successAlert}

                    </Alert>
                }
                {failureAlert !== '' &&
                    <Alert status='error' variant='left-accent' w='40vw'>
                        <AlertIcon />
                        {failureAlert}
                    </Alert>
                }
            </Stack>
            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
                Show less...
            </Text></Link>
            <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
                Show more...
            </Text></Link>
            {/* Delete Product */}
        </Box>
    );
}
