import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Keyword from "views/admin/keyword";
import Uncrawled from "views/admin/Uncrawled";
import Suspicious from "views/admin/suspicious";
import TestProduct from "views/admin/testProducts";
import DetectedProducts from "views/admin/Detected Product";
import Sellers from "views/admin/sellers";
import Lookalike from "views/admin/lookalike";
import ProductInfo from "views/admin/product_info";
import BrandAcquisition from "./BA/BrandAcquisition";




import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import RegisterPage from "views/auth/signup";


const routes = [
  {
    name: "Overview",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "keywords",
    layout: "/admin",
    path: "/keywords",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Keyword,
  },
  {
    name: "Uncrawled Products",
    layout: "/admin",
    path: "/uncrawled",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Uncrawled,
    secondary: true,
  },
  {
    name: "Detected Products",
    layout: "/admin",
    path: "/products",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: DetectedProducts,
    secondary: true,
  },
  {
    name: "Suspicious Products",
    layout: "/admin",
    path: "/suspicious",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Suspicious,
    secondary: true,
  },
 
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },

  {
    name: "Test Purchase",
    layout: "/admin",
    path: "/test",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: TestProduct,
    secondary: true,
  },
  {
    name: "Sellers",
    layout: "/admin",
    path: "/sellers",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Sellers,
    secondary: true,
  },
  {
    name: "Product Info",
    layout: "/admin",
    path: "/productinfo",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: ProductInfo,
    secondary: true,
  },
  {
    name: "Check Lookalike",
    layout: "/admin",
    path: "/lookalike",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Lookalike,
    secondary: true,
  },

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },

];

const extraRoutes = [
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: RegisterPage,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  }
// BrandAcquisition



];

export default routes;
export { extraRoutes };
